import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import "../styles/contact.css";
import logo from "./logo.png";
import Swal from 'sweetalert2'
import { useState } from "react";


/*const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];


const Contact = () => {
  const [formStatus, setFormStatus] = React.useState('Send')
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Sent')
    const { name, email, message } = e.target.elements
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
    }
    console.log(conFom)
  } 
    
  const[initialVelues] ={name:'',email:'',message:''};
    const[formvalue, setFormValue] = useState(initialVelues);

    const handlechange= (e)=>{
      const {name,value}=e.target;
      setFormValue({...formvalue,[name]:value});
      console.log(formvalue);
    }
  
*/
  function Contact(){
    
    const[data,setdata]=useState("");
    const HandleButton = (event)=>{
     // event.curruntTarget.disabled = true;
     event.curruntTarget.disabled = true;
    }


    const onSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
  
      formData.append("access_key", "d6e3da85-39b8-4b83-8db2-db7d7554fcae");
      <input type="hidden" name="subject" value="New Bhagat"></input>
  
      const object = Object.fromEntries(formData);
      const json = JSON.stringify(object);
  
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      }).then((res) => res.json());
  
      if (res.success) {
        Swal.fire({
          title: "Success!",
          text: "Message successfully send !",
          icon: "success"
        });
      }

    }
  
  return (
    <Helmet title="Contact">


      <section className="align__contact" >
        <div className="back">
          <div className="contact__info">
            <img src={logo} className="imgsiz" />
            {/* <h2 className="contactfont">Contact us...</h2> */}
            <form onSubmit={onSubmit} className="formstil" action="https://formspree.io/f/xanwgnwo" method="POST">
            <input type="hidden" name="subject" value="Feedback : PurpleCap Software"></input>
              <div className="mb-3 ">
                <label className="form-label" htmlFor="name">
                  Name:
                </label>
                <input className="form-control"  type="text" id="name" name="name"  required  onChange={(event)=>setdata(event.target.value)}  />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="email">
                  Email:
                </label>
                <input className="form-control"  type="email" id="email"  name="email"  required  onChange={(event)=>setdata(event.target.value)} />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="message">
                  Message:
                </label>
                <textarea className="form-control"  id="message" name="message"   required  onChange={(event)=>setdata(event.target.value)} />
              </div>
              <button className="btnstil" type="submit"
               onClick={(event)=> HandleButton(event)}
               disabled={!data}
              >
                {/*{formStatus}*/} Submit
              </button>
            </form>
          </div>
        </div>



      </section>
    </Helmet>
  );

};

export default Contact;