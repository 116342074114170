import React, {useEffect} from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/game-item.css";
import { FaDownload } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { Scrollbars } from 'react-custom-scrollbars';


const GameItem = (props) => {
  const { imgUrl, gameName, describe, gameUrl, gamePlyStrUrl } = props.item;
  
  const handleDownload = (event) => {
    // Prevent the default action (like form submission or page refresh)
    event.preventDefault();

    // Check if the gameUrl is an APK file
    if (gameUrl && gameUrl.endsWith('.apk')) {
      // Create a temporary <a> tag to trigger the download
      const link = document.createElement("a");
      link.href = gameUrl;        
      //link.download = gameUrl; // file name as a full URL
      document.body.appendChild(link);            // Append the link to the document body
      link.click();                 // Simulate the click event to start the download
      document.body.removeChild(link);       // Remove the link after triggering the download
    } else {
      // If the URL is not an APK file, you could optionally show an alert or handle the case accordingly
      alert("This game is not available for download as an APK.");
    }
  };
  return (

    <Col lg="4" md="4" sm="6" className="mb-5 " >
    
        <div className="game__item">
          <div className="game__img">
            <img src={imgUrl} alt="" className="w-100" />
          </div>

          <div className="game__item-content mt-4">
            <h4 className="section__title text-center">{gameName}</h4>
            <Scrollbars style={{ height: "100px" }}>
              <h6 className="game__describe text-center mt-">
                {describe}
              </h6>
            </Scrollbars>

            <Link to={`${gamePlyStrUrl}`}>
              <button id="bw" className="game__item-btn game__btn-details">
                <IoLogoGooglePlaystore style={{ color: '#fff' }} />
              </button>
            </Link>

            <Link to={`${gameUrl}`}>
              <button id="bw" className="game__item-btn game__btn-details" onClick={handleDownload} >
                <FaDownload style={{ color: '#fff' }} />
              </button>
            </Link>

          </div>
        </div>

    </Col>

  );
};

export default GameItem;
