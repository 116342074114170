import React, { useRef } from "react";
import logo from './logo.png'
import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../styles/header.css";

const navLinks = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/games",
    display: "Games",
  },
  {
    path: "/contact",
    display: "Contact",
  },
  {
    path: "/PrivecyPolicy",
    display: "Privecy Policy",
  },

];

const Header = () => {
  const menuRef = useRef(null);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  return (
    <div className="header">

      {/* ========== Header message =========== */}
      <div className="main__navbar1" >
        <h2 className="toptext">PurpleCap & Juju Software</h2>
     
      {/* ========== main navigation =========== */}

      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i class="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            {/* -------------LOGO------------- */}

            <Col lg="4" md="3" sm="4">
              <div className="logo">
                <h1>
                  <Link to="/home" className=" d-flex align-items-center gap-2">
                    <img src={logo} alt="PurpleCap" className="logo_align" />
                  </Link>
                </h1>
              </div>
            </Col>

            {/* -----------Navigation button---------- */}


            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <div className="menu">
                {navLinks.map((item, index) => (
                  <NavLink
                    to={item.path}
                    className={(navClass) =>
                      navClass.isActive ? "nav__active nav__item" : "nav__item"
                    }
                    key={index}
                  >
                    {item.display}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </Container>
        <div className="headertext"></div>
      </div>

      
      </div>

    </div>

  );
};

export default Header;
