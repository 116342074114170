import React from "react";

import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import AboutSection from "../components/UI/AboutSection";
import GameData from "../assets/data/gameData";
import GameItem from "../components/UI/GameItem";
import "../styles/home.css";
// import HeroSlider from "../components/UI/HeroSlider";

const Home = () => {
  return (
    <div className="hm">
      <Helmet title="Home">

        {/* <HeroSlider /> */}
        <AboutSection />

        <section>
          <Container>
            <Row>
              <Col lg="12" className="text-center mb-5">
                <h2 className="section__title">Games Like...</h2>
              </Col>

              {GameData.slice(0, 6).map((item) => (
                <GameItem item={item} key={item.id} />
              ))}
            </Row>
            <button className=" reserve__btn mt-4">
              <Link to="/games">More games...</Link>
            </button>
          </Container>
        </section>



      </Helmet>
    </div>
  );
};

export default Home;
