// import all images from assets/images directory
import img01 from "../all-images/game-img/game-10.jpeg";
import img02 from "../all-images/game-img/game-11.png";
import img03 from "../all-images/game-img/game-12.jpeg";
import img04 from "../all-images/game-img/game-13.jpeg";
import img05 from "../all-images/game-img/game-14.jpg";
import img06 from "../all-images/game-img/game-15.jpeg";
import img07 from "../all-images/game-img/game-16.jpeg";
import img08 from "../all-images/game-img/game-17.png";
import img09 from "../all-images/game-img/game-18.png";
import img10 from "../all-images/game-img/game-19.jpeg";
import img11 from "../all-images/game-img/game-20.jpeg";
import img12 from "../all-images/game-img/game-21.png";

// import all apk from assets/game-apk directory
import game12 from "../game-apk/ludowinner.apk";
import game11 from "../game-apk/mbf.apk";
import game10 from "../game-apk/blackboard.apk";
import game9 from "../game-apk/mathquiz.apk";
import game8 from "../game-apk/IndianLudo.apk";
import game7 from "../game-apk/color_memory_chess.apk";
import game6 from "../game-apk/dronecontrol.apk";
// import game5 from "../game-apk/ludowinner.apk";
import game4 from "../game-apk/mathbingo.apk";
import game3 from "../game-apk/tigergoat.apk";
import game2 from "../game-apk/snakes&ladders.apk";
import game1 from "../game-apk/braingame.apk";

const gameData = [

  {
    id: 12,
    gameName: "Ludo Game: Ludo winner",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.purplecap.kingofludowinner",
    gameUrl: game12,
    imgUrl: img01,

    describe: "Ludo game traces its lineage back to 6th century India. Ludo is derived from the game Pachisi. Ludo is also very similar to Spanish board game Parchís.",
    description:
      " Ludo game traces its lineage back to 6th century India. Ludo is derived from the game Pachisi. Ludo is also very similar to Spanish board game Parchís.",
  },

  {
    id: 11,
    gameName: "Multiplayer Math Bingo",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.purplecap.mathbingo",
    gameUrl: game11,
    imgUrl: img02,
    describe: "Math BINGO is a free educational Math Bingo Addition, Subtraction, Multiplication or Division Game For Kids and children to practice maths on your phone/tablet. Choose from addition, subtraction, multiplication, division or all BINGO, then select a level of difficulty 1 to 7.",
    description:
      " Math BINGO is a free educational Math Bingo Addition, Subtraction, Multiplication or Division Game For Kids and children to practice maths on your phone/tablet. Choose from addition, subtraction, multiplication, division or all BINGO, then select a level of difficulty 1 to 7."
  },

  {
    id: 10,
    gameName: "Black Board",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.purplecap.blackboard",
    gameUrl: game10,
    imgUrl: img03,
    describe: "Simple blackboard with some advanced options. You can draw anything you want with different colors. You can use it for drawing, illustrations, math calculations and etc.",
    description:
      "Simple blackboard with some advanced options. You can draw anything you want with different colors. You can use it for drawing, illustrations, math calculations and etc.",
  },

  {
    id: 9,
    gameName: "Math Quiz Free",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.purplecap.mathquiz",
    gameUrl: game9,
    imgUrl: img04,
    describe: "Math Quiz is about math operators. Learn to add, subtract, multiply and divide , want to brush up your knowledge this application is right for you.",
    description:
      "Math Quiz is about math operators. Learn to add, subtract, multiply and divide , want to brush up your knowledge this application is right for you.",
  },

  {
    id: 8,
    gameName: "Indian Ludo : Changa Po : Mini",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.purplecap.indianludo",
    gameUrl: game8,
    imgUrl: img05,
    describe: "Indian Ludo(AngMang ChowkChang) board game. 2-4 players. Roll the digital dice and have fun with your friends and family.",
    description:
      "Indian Ludo(AngMang ChowkChang) board game. 2-4 players. Roll the digital dice and have fun with your friends and family.",
  },

  {
    id: 7,
    gameName: "Color Memory Chess",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.purplecap.memorychess",
    gameUrl: game7,
    imgUrl: img06,
    describe: "This game helps the child train his memory, learn colors and refine his fine motor skills by manipulating the chess pieces.",
    description:
      " This game helps the child train his memory, learn colors and refine his fine motor skills by manipulating the chess pieces.",
  },

  {
    id: 6,
    gameName: "Drone Control",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.purplecap.drone",
    gameUrl: game6,
    imgUrl: img07,
    describe: "Drone Control is a jumping game based on physics world. Help the Drone to reach the top. Try to avoid the obstacles and swing through the blocks to reach the top.",
    description:
      " Drone Control is a jumping game based on physics world. Help the Drone to reach the top. Try to avoid the obstacles and swing through the blocks to reach the top.",
  },

  {
    id: 5,
    gameName: "Math Bingo Grade K-4",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.purplecap.mathbingopro",
    gameUrl: "https://purplecap.co.in/app/mbg.apk",
    imgUrl: img08,
    describe: "Math BINGO is a educational Math Bingo Addition, Subtraction, Multiplication or Division Game For Kids and children to practice maths on your phone/tablet.",
    description:
      "Math BINGO is a educational Math Bingo Addition, Subtraction, Multiplication or Division Game For Kids and children to practice maths on your phone/tablet."
  },
  {
    id: 4,
    gameName: "Math Bingo Free",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.jujusoftware.mathbingofree",
    gameUrl: game4,
    imgUrl: img09,
    describe: "Math BINGO is a free educational Math Bingo Addition, Subtraction, Multiplication or Division Game For Kids and children to practice maths on your phone/table.",
    description:
      "Math BINGO is a free educational Math Bingo Addition, Subtraction, Multiplication or Division Game For Kids and children to practice maths on your phone/table."
  },
  {
    id: 3,
    gameName: "Tiger Vs Goat Multiplayer",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.jujusoftware.shologutibead16",
    gameUrl: game3,
    imgUrl: img10,
    describe: "Tiger Vs Goat (16 Beads) is a fun, exciting, strategy board game to play with friends and family. This board game played between 2 players, or you can play this game with computer. This game is specially famous in India, Bangladesh, Srilanka, Nepal, Pakistan, Saudi Arabia, Indonesia.",
    description:
      "Tiger Vs Goat (16 Beads) is a fun, exciting, strategy board game to play with friends and family. This board game played between 2 players, or you can play this game with computer. This game is specially famous in India, Bangladesh, Srilanka, Nepal, Pakistan, Saudi Arabia, Indonesia."
  },
  {
    id: 2,
    gameName: "Snakes and Ladders",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.jujusoftware.snakesandladders",
    gameUrl: game2,
    imgUrl: img11,
    describe: "Snakes Ladders is also known as saap sidi which is fantasy for every child and we are going to Relive with this amazing snake and ladder game. It is played between two or more players on a gameboard having numbered, gridded squares.",
    description:
      "Snakes Ladders is also known as saap sidi which is fantasy for every child and we are going to Relive with this amazing snake and ladder game. It is played between two or more players on a gameboard having numbered, gridded squares."
  },
  {
    id: 1,
    gameName: "Brain game : Picture Matc",
    gamePlyStrUrl: "https://play.google.com/store/apps/details?id=com.jujusoftware.memorymatch",
    gameUrl: game1,
    imgUrl: img12,
    describe: "This simple but elegant memory card matching game was designed for anyone that wants brain improvement. Match the same pair of images and test & improve your memory with this game.",
    description:
      "This simple but elegant memory card matching game was designed for anyone that wants brain improvement. Match the same pair of images and test & improve your memory with this game."
  }
];

export default gameData;

/*
import React, { UseEffect } from "react";


const gameData = () =>{
  let API = "https://api.teleport.org/api/cities/?search=tokyo";
  console.log("API");

 const fetchApiData =async (url) => {
    try{
      const res = await fetch (url);
      const gameData = await res.json();
      console.log (gameData);
      
    }catch (error){
     console.log(error);
    }
  };
  UseEffect(()=>{
    fetchApiData(API);
  },[]);


  return(
  <>
    <h2>my new content</h2>
  </>
  ); 
}

export default gameData;
import { useState, useEffect } from 'react';

const Fetch = () => {
  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/photos')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setPhotos(data);
      });
  }, []);
  return (
    <div>
      {photos.map((photo) => (
        <img key={photo.id} src={photo.url} alt={photo.title} width={100} />
      ))}
    </div>
  );
};
export default Fetch; 


*/



